import React, { useState, useRef, useEffect } from 'react';
import Dashboard from './components/Dashboard';
import "../App.css"
import MenuBar from '../layouts/MenuBar'

import WaitingForData from '../layouts/WaitingForData';
import { Link } from 'react-router-dom';
import { useTelemetry } from '../contexts/TelemetryContext';



// This should work dynamically for whatever car is selected.
// /details/:carID
// This will be a page that shows the details of the car selected when :carID's owner is the current user

function App() {
  // State variables
  const [telemetry, newTelemetry] = useState([]);
  const [settings, newSettings] = useState([])
  const [fetchURL,setFetchURL] = useState()

  const {latestTelemetry} = useTelemetry()

  useEffect(() => {
    // Set the telemetry to the latest telemetry
    if (latestTelemetry) {
      newTelemetry(latestTelemetry)
    }
  })

  const LOCAL_STORAGE_SETTINGS_KEY='dashboardApp.settings'
  // Load saved settings from browser storage
  useEffect(() => {
    const storedSettings = JSON.parse(localStorage.getItem(LOCAL_STORAGE_SETTINGS_KEY));
    if (storedSettings) {newSettings(storedSettings)};
  },[]) 
  // TODO - rewrite to load settings from firebase when 

  return (
    <>

    <MenuBar/>
    {(telemetry) ? 
    <Dashboard telemetry={telemetry} settings={settings}/> 
    :
    (settings?.dweet_name) ?
      <div className="w-75 mx-auto mt-5">
        <WaitingForData />
      </div>
    : 
    <div className="w-75 mx-auto mt-5">
      <Link to={`/configure?${4}`} className="btn btn-warning btn-block">Set Dweet Thing Name Here</Link>
    </div>
    }
  
    </>
  );
}
export default App;
